define("ember-modal-dialog/components/liquid-dialog", ["exports", "@ember-decorators/component", "ember-modal-dialog/components/basic-dialog", "ember-modal-dialog/templates/components/liquid-dialog"], function (_exports, _component, _basicDialog, _liquidDialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  let LiquidDialog = _exports.default = (_dec = (0, _component.layout)(_liquidDialog.default), _dec(_class = class LiquidDialog extends _basicDialog.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "hasOverlay", true);
      _defineProperty(this, "variantWrapperClass", 'emd-animatable');
    }
    init() {
      super.init(...arguments);
      this.containerClassNames?.push('liquid-dialog');
    }
  }) || _class);
});